import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logoutFunc } from "../../../Features/AuthSlice";
import { useSelector } from "react-redux";
import { emailVerify, otpResend } from "../../../Features/AuthSlice";
import { clearSession } from "../../../Features/SetsAPI/SetsSlice";
import { endTest } from "../../../Features/SetsAPI/LocalSetSlice";
import { clearAllValue } from "../../../Features/SetsAPI/LocalSetSlice";
import { toast } from "react-toastify";
import { emptyProfile } from "../../../Features/Profile_management/profileSlice";
const NotVerified = () => {
  const dispatch = useDispatch();
  let allInputs = useRef([]);
  let token = useSelector((state) => state.auth.currentUser.token);
  // let email = useSelector((state) => state.profile.profileInfo.email);
  let email = localStorage.getItem("email");
  const [OTP, setOTP] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const handleChange = (e) => {
    if (e.target.validity.valid) {
      setOTP({ ...OTP, [e.target.name]: e.target.value });
    }
    let index = allInputs.current.findIndex((item) => item === e.target);
    let nextIndex = index + 1;
    if (nextIndex > allInputs.current.length) {
      nextIndex = 0;
    }
    allInputs.current[nextIndex].focus();
    allInputs.current[nextIndex].select();
  };
  const concatenateOTP = (allEntires) => {
    return Object.values(allEntires).join("");
  };
  const handleOTPVerification = () => {
    let otp = concatenateOTP(OTP);
    if (otp.length === 4) {
      dispatch(emailVerify({ otp, token }))
    } else {
      toast.error("Please Insert Complete OTP.");
    }
  };
  const handleResendOTP = () => {
    dispatch(otpResend({ email }));
  };
  const otpName = [
    {
      name: "first",
    },
    {
      name: "second",
    },
    {
      name: "third",
    },
    {
      name: "fourth",
    },
  ];
  return (
    <div className="notverified-container">
      <Container>
        <div className="text-end">
          <button
            className="btn btn-small logOut--btn ms-0"
            onClick={() => {
              dispatch(clearAllValue());
              dispatch(endTest());
              dispatch(emptyProfile());
              dispatch(clearSession());
              dispatch(logoutFunc());
            }}
          >
            Logout
          </button>
        </div>
        <div className="otp__container text-center">
          <h2>Please Enter the OTP to Verify your Account</h2>
          <p>A OTP(One Time Password) has been sent to your email.</p>
          {otpName.map((item, id) => (
            <input
              ref={(el) => (allInputs.current[id] = el)}
              key={id}
              value={OTP[item.name]}
              type="number"
              placeholder="X"
              pattern="[0-9]*"
              onChange={handleChange}
              name={item.name}
              min={0}
              max={9}
            />
          ))}
          <button onClick={handleOTPVerification} className="btn">
            Verify OTP
          </button>
          <p>Didn't received any code?</p> &nbsp;
          <button onClick={handleResendOTP} className="resendOtp">
            Resend OTP
          </button>
        </div>
      </Container>
    </div>
  );
};

export default NotVerified;
